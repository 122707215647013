import React from "react";
import {PanelTabFactory, DatasetSectionFactory} from "@kepler.gl/components";

const Noop = () => null;
export function CustomDatasetSectionFactory(...deps) {
  const PanelTabs = DatasetSectionFactory(...deps);
  return Noop;
}

CustomDatasetSectionFactory.deps = DatasetSectionFactory.deps;

export function removeAddData() {
  return [DatasetSectionFactory, CustomDatasetSectionFactory];
}
