import React from "react";
import {
  PanelTabFactory,
  PanelViewListToggleFactory,
} from "@kepler.gl/components";

const Noop = () => null;
export function CustomPanelViewListToggleFactory(...deps) {
  const PanelTabs = PanelViewListToggleFactory(...deps);
  return Noop;
}

CustomPanelViewListToggleFactory.deps = PanelViewListToggleFactory.deps;

export function removeListSelection() {
  return [PanelViewListToggleFactory, CustomPanelViewListToggleFactory];
}
