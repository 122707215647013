import React, {Component} from "react";
import styled, {ThemeProvider} from "styled-components";
import window from "global/window";
import {connect} from "react-redux";

import {theme} from "@kepler.gl/styles";
import {processKeplerglJSON} from "@kepler.gl/processors";
import {replaceMapControl} from "./factories/map-control";
import {replacePanelHeader} from "./factories/panel-header";
import {replacePanels} from "./factories/side-panel";
import {replaceTabs} from "./factories/panel-tabs";
import {removeAddData} from "./factories/add-data-button";
import {removeListSelection} from "./factories/layers-list";
import {AUTH_TOKENS} from "./constants/default-settings";

import {addDataToMap} from "@kepler.gl/actions";

import {useLogoStore} from "./stores/logo";

const KeplerGl = require("@kepler.gl/components").injectComponents([
  replaceMapControl(),
  replacePanels(),
  replacePanelHeader(),
  replaceTabs(),
  removeAddData(),
  removeListSelection(),
]);

/* eslint-enable no-unused-vars */
const keplerGlGetState = (state) => {
  return state.demo.keplerGl;
};

const GlobalStyle = styled.div`
  font-family: ff-clan-web-pro, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.71429;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.labelColor};
  }
`;

const AUTH_URL = process.env["VITE_AUTH_URL"];
const API_URL = `${process.env["VITE_API_URL"]}/v1/graphql`;

export function getProductURL(subdomain, urlParams = {}) {
  const domain = process.env["VITE_DOMAIN"];
  const stage = process.env["VITE_STAGE"];
  let url = `https://${subdomain}.${domain}`;
  if (stage) url = `https://${subdomain}.${stage}.${domain}`;
  const productURL = new URL(url);
  for (const [key, value] of Object.entries(urlParams)) {
    productURL.searchParams.append(key, value);
  }
  return productURL.toString();
}

function fetchAccessToken() {
  return fetch(`${AUTH_URL}/auth/link/refresh`, {
    method: "GET",
    credentials: "include",
  });
}

function getUserProducts(accessToken, userId) {
  return fetch(`${API_URL}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      query: `
      query UserDetails($id: uuid!) {
        users_by_pk(id: $id) {
          accounts {
            account {
              id
              organization_name
              products {
                product {
                  id
                  product_name
                  sub_domain
                  ui_order
                }
              }
            }
          }
        }
      }`,
      variables: {
        id: userId,
      },
    }),
  });
}

function fetchMapData(accessToken) {
  return fetch(`${API_URL}`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      query: `query regional_analysis_geospatial { regional_analysis_geospatial { year datasets }}`,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

class App extends Component {
  state = {
    showBanner: false,
    width: window.innerWidth,
    height: window.innerHeight,
    loading: true,
    keplerData: null,
    accessToken: null,
    products: [],
    user: null,
  };

  componentDidMount() {
    fetchAccessToken()
      .then((data) => data.json())
      .then((data) => {
        const {accessToken, user = {}} = data;
        if (!accessToken)
          window.location.href = getProductURL("app", {
            redirect: window.location.href,
          });
        this.setState({accessToken, user});
        return getUserProducts(accessToken, user.id);
      })
      .then((res) => res.json())
      .then((res) => {
        const accounts = res.data.users_by_pk?.accounts || [];
        const orgName = accounts[0]?.account?.organization_name || "";
        if (orgName) {
          useLogoStore.getState().setLogo(orgName);
        }
        const products = accounts
          .flatMap(({account}) => {
            return account.products.map(({product}) => ({
              id: product.id,
              name: product.product_name,
              acronym: product.product_name
                .split(" ")
                .map((word) => word[0].toUpperCase())
                .join(""),
              order: product.ui_order || Infinity,
              subdomain: product.sub_domain || "",
            }));
          })
          .sort((a, b) => a.order - b.order);
        this.setState({products});
      })
      .then(() => {
        const {accessToken} = this.state;
        return fetchMapData(accessToken);
      })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        const keplerData = data.data.regional_analysis_geospatial;
        keplerData.forEach(({datasets}) => {
          this.props.dispatch(addDataToMap(processKeplerglJSON(datasets)));
        });
      })
      .catch(() => {
        this.setState({loading: false});
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  render() {
    const {loading, products = []} = this.state;
    console.log("loading", loading);
    const appSwitcherWidth = loading || products.length === 0 ? 0 : 64;
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle
          ref={(node) => {
            node ? (this.root = node) : null;
          }}
        >
          <div
            style={{
              transition: "margin 1s, height 1s",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              zIndex: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                width: appSwitcherWidth,
                top: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  height: "100%",
                  backgroundColor: "#333333",
                  paddingTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {products.map(({id, name, acronym, subdomain, order}) => {
                  return (
                    <a
                      key={id}
                      className="app-button"
                      href={getProductURL(subdomain)}
                      style={{
                        color: "#FFFFFF",
                        textAlign: "center",
                        background: "#000",
                        borderRadius: 4,
                        marginBottom: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 40,
                        height: 40,
                        cursor: "pointer",
                      }}
                    >
                      {acronym}
                    </a>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                bottom: 0,
                left: appSwitcherWidth,
              }}
            >
              <KeplerGl
                mapboxApiAccessToken={AUTH_TOKENS.MAPBOX_TOKEN}
                id="map"
                /*
                 * Specify path to keplerGl state, because it is not mount at the root
                 */
                getState={keplerGlGetState}
                width={window.innerWidth - appSwitcherWidth}
                height={window.innerHeight}
              />
            </div>
            {loading ? (
              <svg className="spinner" viewBox="0 0 50 50" fill="none">
                <path
                  d="M47 25C47 29.6697 45.5141 34.2183 42.7574 37.9875C40.0007 41.7567 36.1161 44.5509 31.666 45.9658C27.2158 47.3807 22.4308 47.3429 18.0035 45.8578C13.5762 44.3728 9.73634 41.5175 7.03954 37.7052C4.34274 33.8929 2.92894 29.3214 3.00275 24.6523C3.07656 19.9832 4.63414 15.4586 7.45009 11.7335C10.266 8.00835 14.1943 5.27585 18.6663 3.93145C23.1383 2.58706 27.922 2.7005 32.3253 4.25536"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : null}
          </div>
        </GlobalStyle>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({dispatch});

export default connect(mapStateToProps, dispatchToProps)(App);
