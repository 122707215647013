import React from "react";
import {PanelHeaderFactory, SidePanelFactory} from "@kepler.gl/components";

const NoSidebar = () => <></>;
export function CustomSidePanelFactory(...deps) {
  const SidePanels = SidePanelFactory(...deps);
  const layersPanel = SidePanels.defaultProps.panels.find(
    (panel) => panel.id === "layer"
  );
  SidePanels.defaultProps = {
    ...SidePanels.defaultProps,
    panels: [layersPanel],
  };
  return SidePanels;
}

CustomSidePanelFactory.deps = SidePanelFactory.deps;

export function replacePanels() {
  return [SidePanelFactory, CustomSidePanelFactory];
}
