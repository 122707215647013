import React from "react";
import {PanelHeaderFactory} from "@kepler.gl/components";

import {useLogoStore} from "../stores/logo";

const Logo = () => {
  const {logo} = useLogoStore();
  return (
    <h1 style={{color: "#fff", fontWeight: "bold", fontSize: 20}}>{logo}</h1>
  );
};

export function CustomPanelHeaderFactory(...deps) {
  const PanelHeader = PanelHeaderFactory(...deps);
  PanelHeader.defaultProps = {
    ...PanelHeader.defaultProps,
    logoComponent: Logo,
    actionItems: [],
  };
  return PanelHeader;
}

CustomPanelHeaderFactory.deps = PanelHeaderFactory.deps;

export function replacePanelHeader() {
  return [PanelHeaderFactory, CustomPanelHeaderFactory];
}
