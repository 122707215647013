import React from "react";
import {PanelTabFactory} from "@kepler.gl/components";

const Noop = () => null;
export function CustomPanelTabFactory(...deps) {
  const PanelTabs = PanelTabFactory(...deps);
  return Noop;
}

CustomPanelTabFactory.deps = PanelTabFactory.deps;

export function replaceTabs() {
  return [PanelTabFactory, CustomPanelTabFactory];
}
